/*
 * Copyright 2015-2019, Circadence Corporation.  All Rights Reserved.
 * This document contains confidential information of Circadence Corporation and may not be duplicated or disclosed to
 * parties other than the intended recipient without the prior written consent of Circadence Corporation.
 */
const reactAad = require('react-aad-msal');
const msal = require('msal');
let provider;

const logger = new msal.Logger(
  (logLevel, message, containsPii) => {
    console.log('[aadb2c]', message)
  },
  {
    level: msal.LogLevel.Info,
    piiLoggingEnabled: false
  }
);

export function initializeProvider(configuration) {
  if (provider) {
    logger.info('initializeProvider: Returning cached provider.');
    return provider
  }

  const {auth: {clientId, authority, scopes}} = configuration;
  const auth = {
    validateAuthority: false,
    clientId,
    authority
  };
  logger.info(`initializeProvider: Creating new provider.`);
  // https://docs.microsoft.com/en-us/azure/active-directory/develop/msal-js-initializing-client-applications#configuration-options
  provider = new reactAad.MsalAuthProvider(
    {
      auth,
      system: {
        logger
      },
      cache: {
        cacheLocation: 'sessionStorage',
        storeAuthStateInCookie: false
      }
    },
    {
      scopes
    },
    {
      loginType: reactAad.LoginType.Popup,
      tokenRefreshUri: window.location.origin + '/auth.html'
    }
  );
  return provider
}

export function getProvider() {
  return provider
}

export function getAccessToken() {
  // TODO This is supposed to check cache before attempting to fetch another but
  // that's not currently how it's working. We don't want to fetch an access token each time.
  return provider.getAccessToken()
}

/**
 *
 * @return {Promise<IdTokenResponse>}
 */
export function getIdToken(forceRefresh = false) {
  return provider.getIdToken({forceRefresh})
}

export function getAccessTokenDecoded() {
  return getAccessToken()
    .then(token => {
      try {
        return JSON.parse(window.atob(token.split('.')[1]))
      } catch (e) {
        console.log(`getAccessTokenDecoded failed: ${e}`)
      }
    });
}

export function logout() {
  provider.logout()
}
