import _get from 'lodash.get'
import {REDUCER_KEY} from './reducer'

export function selectError(globalState) {
  return _get(globalState, `${REDUCER_KEY}.identity.error`)
}

/**
 * Gets the status of the AAD system.
 * @param globalState
 * @return {*}
 */
export function selectStatus(globalState) {
  return _get(globalState, `${REDUCER_KEY}.status`)
}
/**
 * Selects the name which is the display name claim of the identity.
 * @param globalState
 * @return {*}
 */
export function selectIdentityName(globalState) {
  return _get(globalState, `${REDUCER_KEY}.identity.claims.name`)
}

/**
 * Get the sub claim of the id token.
 *
 * @param globalState
 *
 * @return sub claim
 */
export function selectIdentitySubject(globalState) {
  return _get(globalState, `${REDUCER_KEY}.identity.claims.sub`)
}

/**
 * Gets the customer ID from the identity token.
 *
 * @param globalState
 *
 * @return (undefined | string)
 */
export function selectIdentityCustomerId(globalState) {
  return _get(globalState, `${REDUCER_KEY}.identity.claims.extension_CustomerId`)
}

export function selectIdentityExpiry(globalState) {
  return _get(globalState, `${REDUCER_KEY}.identity.claims.exp`)
}

export function selectIdentityIat(globalState) {
  return _get(globalState, `${REDUCER_KEY}.identity.claims.iat`)
}

export function selectAccessToken(globalState) {
  return _get(globalState, `${REDUCER_KEY}.access.accessToken`)
}

export function selectIsCmtAdmin(globalState) {
  return _get(globalState, `${REDUCER_KEY}.identity.claims.extension_IsCmtAdmin`) === true
}

export function selectIsCustomerAdmin(globalState) {
  return _get(globalState, `${REDUCER_KEY}.identity.claims.extension_IsCustomerAdmin`) === true
}

export function selectIsPlayer(globalState) {
  return _get(globalState, `${REDUCER_KEY}.identity.claims.extension_IsPlayer`) === true
}

export function selectIsAresPlayer(globalState) {
  return _get(globalState, `${REDUCER_KEY}.identity.claims.extension_IsAresPlayer`) === true
}

export function selectIsAresTrainer(globalState) {
  return _get(globalState, `${REDUCER_KEY}.identity.claims.extension_IsAresTrainer`) === true
}

export function selectIsRegistered(globalState) {
  return _get(globalState, `${REDUCER_KEY}.identity.claims.extension_IsRegistered`) === true
}

export function selectEmails(globalState) {
  return _get(globalState, `${REDUCER_KEY}.identity.claims.emails`)
}
