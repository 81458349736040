export const REDUCER_KEY = 'aad';
export const AAD_STATUS_UNAUTHENTICATED = "Unauthenticated";
export const AAD_STATUS_PENDING = "InProgress";
export const AAD_STATUS_AUTHENTICATED = "Authenticated";

const initialState = {
  // The identity claims
  identity: undefined,

  // The access token with scopes
  access: undefined,

  // The account details
  account: undefined,

  // The status of the AAD system
  status: AAD_STATUS_UNAUTHENTICATED
};

/**
 * A reducer that can be used with Microsoft's MSAL to capture important events with the client.
 * @param state
 * @param action
 * @return {{access, identity, account}&{identity: {error: {errorMessage: *, errorCode: *}}}}&{access: undefined, identity: undefined} new state
 */
export default function reducer(state = initialState, action) {
  //TODO other events?
  switch (action.type) {
    case 'AAD_INITIALIZING':
      return initialState;
    case 'AAD_LOGIN_SUCCESS':
      const {account: {accountIdentifier, homeAccountIdentifier, environment}} = action.payload;
      return {...state, account: {accountIdentifier, homeAccountIdentifier, environment}};
    case 'AAD_LOGOUT_SUCCESS':
      return {...state, identity: undefined, access: undefined, account: undefined};
    case 'AAD_ACQUIRED_ACCESS_TOKEN_SUCCESS':
      return {...state, access: action.payload};
    case 'AAD_ACQUIRED_ID_TOKEN_SUCCESS':
      const {state: tokenState, idToken: {claims}} = action.payload;
      return {...state, identity: {state: tokenState, claims}};
    case 'AAD_ACQUIRED_ACCESS_TOKEN_ERROR':
    case 'AAD_ACQUIRED_ID_TOKEN_ERROR':
    case 'AAD_LOGIN_ERROR':
      const {errorCode, errorMessage} = action.payload;
      return {...initialState, identity: {error: {errorCode, errorMessage}}};
    case 'AAD_AUTHENTICATED_STATE_CHANGED':
      const {payload: status} = action;
      return {...state, status};
    default:
      return state
  }
}
