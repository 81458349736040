import React, {useEffect, useState} from 'react'
import {getClientConfiguration} from './functions'
import PropTypes from 'prop-types';

const noop = () => <div>DEFAULT</div>;
/**
 * A configuration component which acquires any pre-launch configuration for the client. This configuration is
 * documented in the Readme for this project. The only child of this component should be a function that takes
 * three arguments:
 *    - configuration: The configuration object retrieved.
 *    - isFetching: If this component is waiting on the configuration.
 *    - error: An error object (if one occurs).
 *
 * After a configuration is acquired from the server the optional `onConfiguration` callback is called first
 * with the configuration. This gives you a dedicated hook to take action with the configuration before any render
 * cycle that may depend on this new state. You could also perform logic in the child function.
 *
 * @param values Canned configuration to use. If this is present the configuration service is not dialed.
 * @param onConfiguration A hook to perform side effects with the configuration before any state is changed here.
 * @param location The location to be used instead of window.location. This is used as a key for the configuration.
 * @param children A function (described above)
 *
 * @return {*}
 * @constructor
 */
export default function Configuration({values = undefined, onConfiguration = noop, location, children}) {
  const [configuration, setConfiguration] = useState(values);
  const [fetching, setFetching] = useState(true);
  const [error, setError] = useState(undefined);
  useEffect(() => {
    if (configuration) {
      onConfiguration && onConfiguration(configuration);
      setFetching(false);
    } else {
      getClientConfiguration(location || window.location)
        .then(conf => {
          onConfiguration && onConfiguration(conf);
          setConfiguration(conf)
        })
        .catch((err) => {
          console.error(err);
          setError(err)
        })
        .finally(() => {
          setFetching(false)
        })
    }
  });

  return children(configuration, fetching, error)
}

Configuration.propTypes = {
  values: PropTypes.object,
  onConfiguration: PropTypes.func
};
